const state = {
    isIndeterminate: false,
}
const mutations = {
    CHANGE_ISINDETERMINATE: (state, data) => {
        state.isIndeterminate = data
    },
}

const actions = {
    //   changeIsMoblie({ commit }, data) {
    //     commit('CHANGE_ISMOBOLIE', data)
    //   },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
