import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        redirect: '/home',
        component: () => import('@/views/index.vue'),
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/home.vue'),
            },
            {
                path: '/help',
                name: 'Help',
                component: () => import('@/views/help.vue'),
            },
            {
                path: '/lottery',
                name: 'Lottery',
                component: () => import('@/views/lottery.vue'),
            },
            {
                path: '/info',
                name: 'Info',
                component: () => import('@/views/info.vue'),
            },
            // {
            //     path: '/list',
            //     name: 'List',
            //     component: () => import('@/views/home/list/index.vue'),
            // },
            // {
            //     path: '/login',
            //     name: 'Login',
            //     component: () => import('@/views/login//login/index.vue'),
            // },
            // {
            //     path: '/register',
            //     name: 'Register',
            //     component: () => import('@/views/login/register/index.vue'),
            // },
            // {
            //     path: '/changePassword',
            //     name: 'ChangePassword',
            //     component: () => import('@/views/login/changePassword/index.vue'),
            // },
            // {
            //     path: '/user',
            //     name: 'User',
            //     component: () => import('@/views/user/index.vue'),
            // },
        ],
    },
    {
        path: '*',
        redirect: '/home',
    },
]

const router = new VueRouter({
    mode: 'history',
    base: '/', //process.env.BASE_URL,
    routes,
})

export default router
