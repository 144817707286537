const state = {
    isMobile: false,
    isPhone: false,
    config: {},
    windowSize: 0,
}
const mutations = {
    CHANGE_ISMOBOLIE: (state, data) => {
        state.isMobile = data
    },
    CHANGE_ISPHONE: (state, data) => {
        state.isPhone = data
    },
    SET_WINDOWSIZE: (state, data) => {
        state.windowSize = data
    },
    SET_CONFIG: (state, data) => {
        state.config = data
    },
}

const actions = {
    changeIsMoblie({ commit }, data) {
        commit('CHANGE_ISMOBOLIE', data)
    },
    changeIsPhone({ commit }, data) {
        commit('CHANGE_ISPHONE', data)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
