import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api/api'
import '@/assets/css/common.scss'
import '@/assets/css/main.css'
Vue.prototype.$axios = api
Vue.config.productionTip = false
router.beforeEach(async (to, from, next) => {
    next()
})
const app = new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
console.log()
console.log()
