<template>
    <div>
        <router-view />
    </div>
</template>    
 
<script>
export default {
    components: {},
    data() {
        return {}
    },
    created() {
        this.$axios.get('/getSystemInfo').then((res) => {
            this.$store.commit('common/SET_CONFIG', res)
            document.title = res.title
            let favicon = document.querySelector('link[rel="icon"]')
            favicon.href = res.imgPath + res.icon
        })
    },
    mounted() {
        document.oncontextmenu = new Function('return false;')
        document.onkeydown = () => {
            if (window.event && window.event.keyCode == 123) return false
        }
        this.getScreenSize()
        window.onresize = () => {
            this.getScreenSize()
            this.init()
        }
        this.init()
    },
    methods: {
        init() {
            // 获取屏幕宽度
            var width = document.documentElement.clientWidth // 设置根元素字体大小。此时为宽的10等分
            document.documentElement.style.fontSize = width / 10 + 'px'
        },
        getScreenSize() {
            let widthWindow = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
            this.$store.commit('common/SET_WINDOWSIZE', widthWindow)
            let sUserAgent = navigator.userAgent.toLowerCase()
            let mob = /ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/
            let bool = mob.test(sUserAgent) || widthWindow <= 680 ? true : false
            let phone = mob.test(sUserAgent) ? true : false
            this.$store.dispatch('common/changeIsMoblie', bool)
            this.$store.dispatch('common/changeIsPhone', phone)
        },
    },
}
</script>

<style lang="scss" scoped>
</style>
