import store from '../store'
import router from '../router'

if (process.env.NODE_ENV == 'development') {
    axios.defaults.baseURL = '/np'
} else if (process.env.NODE_ENV == 'production') {
    axios.defaults.baseURL = '/api/lotto'
}

axios.defaults.timeout = 15000
//拦截器
axios.interceptors.request.use(
    (config) => {
        let token = localStorage.getItem('token') || ''
        if (token) {
            config.headers.Token = token
        }
        config.headers.DeviceType = 1
        config.headers.Lang = 'zh-cn'
        return config
    },
    (error) => {
        return Promise.reject(error)
    },
)
const handleError = (err, url) => {
    if (axios.isCancel(err)) {
        return
    }
    let status = err.status || err.code
    const errStr = err.msg || ''
    let errMsg = errStr.slice(errStr.indexOf(':') + 1)

    //Message.closeAll()
    switch (status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
            store.dispatch('common/loginOut')
            router.replace({
                path: '/home',
            })
            break
        case 10930:
            // Message({
            //     message: '用户不存在',
            //     type: 'error',
            // })
            break
        case 10551:
            break
        default:
            if (errMsg === '请求超时' || status < 0 || !errMsg) {
            } else {
                // Message({
                //     message: errMsg,
                //     type: 'error',
                // })
            }
    }
}
// 响应拦截器
axios.interceptors.response.use(
    (response) => {
        const headers = response.headers
        if (headers['content-type'] === 'application/zip;charset=utf-8') {
            return Promise.resolve(response.data)
        }
        if (response.status === 200) {
            if (response.data.code === 200) {
                const time = response.data.systemTime
                if (time) {
                    //systemTimeHandler.setTime(time)
                }
                return Promise.resolve(response)
            } else {
                if (response.config.url === '/getSiteUrlList') {
                    return
                }
                return Promise.reject(response.data)
            }
        } else {
            return Promise.reject(response)
        }
    },
    (error) => {
        if (error.response && error.response.status) {
            return Promise.reject(error.response.data)
        } else {
            if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
                error.message = '请求超时'
            } else {
                error.message = '服务器错误'
            }
            return Promise.reject(error)
        }
    },
)

export default {
    /**
     * get方法，对应get请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    get: function (url, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: params,
                })
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    handleError(err, url)
                    if (!axios.isCancel(err)) {
                        reject(err)
                    }
                })
        })
    },
    /**
     * post方法，对应post请求
     * @param {String} url [请求的url地址]
     * @param {Object} params [请求时携带的参数]
     */
    post: function (url, params) {
        return new Promise((resolve, reject) => {
            axios
                .post(url, params)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    handleError(err, url)
                    if (!axios.isCancel(err)) {
                        reject(err)
                    }
                })
        })
    },
    gets: function (url, params) {
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                    params: params,
                })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    handleError(err, url)
                    if (!axios.isCancel(err)) {
                        reject(err)
                    }
                })
        })
    },
    baseURL: axios.defaults.baseURL,
}
